<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl mt-5 font-bold text-blue-800">
        Terminal Mastersheet
      </h1>
      <div class="flex flex-wrap xl:-mx-4 pb-5">
        <div class="xl:my-10 xl:px-4 w-full">
          <input
            type="text"
            class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
            name=""
            placeholder="Search Store ID, MSO email, MSO name"
            id=""
            v-model="search"
          />
          <button
            @click="handleSearchBtn"
            id="searchBTN"
            name="searchBtn"
            class="inline-block uppercase shadow bg-blue-800 hover:bg-blue-500 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 ml-2 rounded"
          >
            Search
          </button>
          <button
            id="clearSearchBtn"
            @click="clearSearchBtn"
            class="inline-block uppercase shadow bg-red-800 hover:bg-red-500 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 ml-2 rounded"
          >
            Clear
          </button>
          <button
            @click="promptModal = true"
            class="inline-block float-right shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-600 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
          >
            Upload File
          </button>
        </div>
        <div
          class="w-full xl:mb-4 xl:mt-5 xl:px-4"
          v-if="filterLocationSheet.length < 1"
        >
          <div
            class="md:w-full px-5 py-20 border-2 border-gray-100 bg-white rounded-lg justify-center"
          >
            <p class="justify-center flex">
              <i class="bi bi-clock-history"></i>
            </p>
            <p class="text-center">
              Mastersheet details will appear here when you upload a file
            </p>
            <div class="justify-center flex mt-5">
              <button
                @click="promptModal = true"
                class="shadow bg-blue-800 hover:bg-blue-700 ml-2 focus:shadow-outline focus:outline-none text-white px-10 py-4 rounded"
              >
                Upload Terminal Mastersheet
              </button>
            </div>
            <div class="justify-center flex mt-5">
              <button
                @click="toggleVersionHistory = true"
                class="block shadow mb-2 text-sm font-bold bg-blue-100 text-blue-800 hover:bg-blue-600 hover:text-white focus:shadow-outline focus:outline-none py-3 px-3 rounded"
              >
                <i class="bi bi-clock-history"></i>
                View Version History
              </button>
            </div>
          </div>
        </div>
        <div class="mt-14 xl:mb-4 xl:mt-5 xl:px-4 w-full" v-else>
          <button
            @click="toggleVersionHistory = true"
            class="inline-block shadow mb-2 text-sm font-bold bg-blue-100 text-blue-800 hover:bg-blue-600 hover:text-white focus:shadow-outline focus:outline-none py-3 px-3 rounded"
          >
            <i class="bi bi-clock-history"></i>
            View Version History
          </button>
          <button
            @click="resetRecords"
            class="inline-block shadow mb-2 text-sm ml-2 float-right font-bold bg-blue-100 text-blue-800 hover:bg-blue-600 hover:text-white focus:shadow-outline focus:outline-none py-3 px-3 rounded"
          >
            <i class="bi bi-arrow-clockwise"></i>
            Reset Records
          </button>
          <div class="w-full" style="max-height: 600px; overflow: auto">
            <table class="shadow-lg bg-white w-full relative border-collapse">
              <tr>
                <th
                  class="text-l p-3 text-left"
                  colspan="12"
                  style="background: #dee6f2"
                >
                  Terminal Mastersheet
                </th>
              </tr>
              <tr>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Terminal ID
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Terminal Type
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Serial Number
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Business ID
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Merchant's Email
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Store ID
                </th>
                <th
                  class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
                >
                  Store Name
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Merchant Classification
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Location ID
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Location Address
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  City/LGA
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  State
                </th>
              </tr>
              <tr
                class="text-xs"
                v-for="(location, i) in filterLocationSheet"
                :key="i"
              >
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.terminalId || "N/A" }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.terminalType || "N/A" }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.serialNumber || "N/A" }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.businessId || "N/A" }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.merchantEmail || "N/A" }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.storeId || "N/A" }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.storeName || "N/A" }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.merchantClassification || "N/A" }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.locationId }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.locationAddress }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.cityLGA }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ location.state }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="mt-3 flex w-full">
          <button
            @click="prevPage(currentPage)"
            :disabled="currentPage === 1 ? isDisabled : !isDisabled"
            class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
            :class="
              currentPage === 1
                ? 'bg-blue-50 text-black'
                : 'bg-blue-900 text-white'
            "
          >
            &laquo; Prev
          </button>

          <button
            @click="nextPage(currentPage)"
            v-if="filterLocationSheet.length > 99"
            class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          >
            Next &raquo;
          </button>
        </div>

        <div
          v-if="promptModal"
          class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
        >
          <div
            class="w-full md:w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4"
          >
            <div class="block">
              <h4 class="font-semibold text-xl">
                Upload Files
              </h4>
              <form
                @submit.prevent="handleLocationUpload"
                class="w-full max-w-full mt-5"
              >
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-full px-3">
                    <p class="text-xs font-bold mb-3">
                      File type <span class="text-red-800">*</span>
                    </p>
                    <p class="text-sm font-semibold">
                      <input
                        type="text"
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        name=""
                        id="fileType"
                        v-model="fileType"
                        required
                        disabled
                      />
                    </p>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-full px-3">
                    <p class="text-xs font-bold mb-3">
                      Tags <span class="text-red-800">*</span>
                    </p>
                    <p class="text-sm font-semibold">
                      <input
                        type="text"
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        name=""
                        id="tags"
                        v-model="tags"
                        required
                      />
                    </p>
                  </div>
                </div>
                <div
                  class="flex flex-wrap -mx-3 mb-6"
                  v-if="uploadURL === null"
                >
                  <div class="w-full md:w-full px-3">
                    <DraggableComponent @handleFile="onHandleFile" />
                    <span class="loader-line" v-if="isLoadingInline"></span>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6" v-else>
                  <div class="w-full md:w-full px-3">
                    <div class="flex justify-center">
                      <div class="text-xs">{{ file.name }}</div>
                    </div>
                    <button
                      type="button"
                      @click="uploadURL = null"
                      class="
                        block
                        mx-auto
                        hover:bg-red-800
                        hover:text-white
                        bg-transparent
                        border border-red-800
                        text-red-800
                        focus:shadow-outline
                        focus:outline-none
                        text-xs
                        font-bold
                        py-2
                        px-4
                        mt-3
                        rounded
                      "
                    >
                      Remove File
                    </button>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-full px-3">
                    <button
                      v-if="file"
                      type="submit"
                      class="shadow bg-blue-800 hover:bg-blue-700 w-full focus:shadow-outline focus:outline-none text-xs text-white px-10 py-4 rounded"
                    >
                      Upload Files
                    </button>
                  </div>
                </div>
              </form>
              <button
                @click="promptModal = false"
                class="
                      block
                      uppercase
                      mx-auto
                      hover:bg-transparent
                      hover:text-red-600
                      bg-transparent
                      focus:shadow-outline
                      focus:outline-none
                      text-red-600 text-xs
                      font-bold
                      py-2
                      px-4
                      rounded
                    "
                style="width:inherit"
              >
                Cancel
              </button>
              <i
                @click="promptModal = false"
                class="bi bi-x text-white icon-cancel cursor-pointer"
                style="font-size: 3rem;"
              ></i>
            </div>
          </div>
        </div>
        <div
          v-if="toggleVersionHistory"
          class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
        >
          <div
            class="w-full md:w-1/2 rounded-lg bg-white p-5 grid grid-cols-1 gap-4"
          >
            <div class="block">
              <h4 class="font-semibold text-xl">
                Version History
              </h4>
            </div>
            <div class="xl:my-5 relative">
              <div class="inline-block">
                <input
                  type="text"
                  class="inline-block relative px-2 ring-1 outline-none ring-blue-500 rounded-sm mr-4 text-sm py-2"
                  name=""
                  placeholder="Search by tag"
                  id=""
                  v-model="versionHistorySearch"
                />

                <i
                  @click="clearVersionFilterBtn"
                  class="bi bi-x-circle-fill icon-sm-cancel cursor-pointer"
                  v-if="versionHistorySearch"
                ></i>
              </div>
              <div class="inline-block">
                <input
                  type="date"
                  class="inline-block relative px-2 ring-1 outline-none ring-blue-500 rounded-sm mr-4 text-sm py-2"
                  name=""
                  placeholder="Search by tag"
                  id=""
                  v-model="searchByDateFrom"
                />
              </div>
              <div class="inline-block">
                <input
                  type="date"
                  class="inline-block relative px-2 ring-1 outline-none ring-blue-500 rounded-sm mr-4 text-sm py-2"
                  name=""
                  placeholder="Search by tag"
                  id=""
                  v-model="searchByDateTo"
                />
              </div>
              <div
                class="inline-block mr-2"
                v-if="searchByDateTo !== '' && searchByDateFrom !== ''"
              >
                <button
                  @click="handleClearDateFilterBtn"
                  id="clearBTN"
                  name="clearBTN"
                  class="inline-block shadow bg-red-800 hover:bg-red-500 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-3 rounded"
                >
                  Clear Date Filter
                </button>
              </div>
              <div class="inline-block">
                <button
                  @click="handleVersionFilterBtn"
                  id="searchBTN"
                  name="searchBtn"
                  class="inline-block shadow bg-blue-800 hover:bg-blue-500 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-3 rounded"
                >
                  Filter log
                </button>
              </div>
            </div>
            <span class="inline-loading" v-if="isLoadingInline"></span>
            <div
              class="xl:my-5"
              style="max-height: 600px; overflow: auto;"
              v-else
            >
              <div
                v-for="(version, index) in filterVersionHistory"
                :key="index"
                class="w-full bg-green-50 rounded px-2 py-3 mb-2 cursor-pointer"
                @click="handleLoadVersionHistory(version)"
              >
                <i class="bi bi-file-earmark-excel-fill text-green-600"></i>
                {{ version.versionName }}
                <span class="text-xs float-right">{{
                  moment(version.createdAt).format("HH:MM DD-MM-YYYY")
                }}</span>
              </div>
            </div>
            <div class="mt-3 flex w-full">
              <button
                @click="prevPageVersionHistory()"
                :disabled="
                  currentPageVersionHistory === 1
                    ? isDisabledVersionHistory
                    : !isDisabledVersionHistory
                "
                class="inline-block uppercase shadow hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
                :class="
                  currentPageVersionHistory === 1
                    ? 'bg-blue-50 text-black'
                    : 'bg-blue-900 text-white'
                "
              >
                &laquo; Prev
              </button>

              <button
                v-if="filterVersionHistory.length > 99"
                @click="nextPageVersionHistory()"
                class="inline-block float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
              >
                Next &raquo;
              </button>
            </div>
          </div>
          <i
            @click="toggleVersionHistory = false"
            class="bi bi-x text-white icon-cancel cursor-pointer"
            style="font-size: 3rem;"
          ></i>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DraggableComponent from "../components/DraggableComponent.vue";
import moment from "moment";
export default {
  components: {
    DraggableComponent,
  },
  data() {
    return {
      moment,
      isLoading: false,
      promptModal: false,

      payload: {},
      search: "",
      fileType: "Mastersheet",
      uploadURL: null,
      isLoadingInline: false,
      data: [],
      isDisabled: true,
      currentPage: 1,
      toggleVersionHistory: false,
      versionHistorySearch: "",
      tags: "",
      file: null,
      filterByParams: "tag",
      searchByDateFrom: "",
      searchByDateTo: "",
      searchActive: false,
      source: "terminal-mastersheet",
      currentPageVersionHistory: 1,
      isDisabledVersionHistory: true,
      versionHistoryActive: false,
      versionHistory: "",
    };
  },
  computed: {
    ...mapGetters(["GET_ALL_SHEETS", "GET_ALL_VERSION_HISTORY"]),
    filterLocationSheet() {
      return this.GET_ALL_SHEETS.data !== undefined &&
        this.GET_ALL_SHEETS.data !== null
        ? this.GET_ALL_SHEETS.data.data
        : [];
    },
    filterVersionHistory() {
      return this.GET_ALL_VERSION_HISTORY.data !== undefined &&
        this.GET_ALL_VERSION_HISTORY.data !== null
        ? this.GET_ALL_VERSION_HISTORY.data.data
        : [];
    },
  },
  async created() {
    this.fetchRecords();
    this.fetchVersionHistory();
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    ...mapActions(["FETCH_SHEETS"]),
    async fetchRecords() {
      this.isLoading = true;
      this.currentPage = 1;
      try {
        let res = await this.$store.dispatch("FETCH_SHEETS", this.source);
        if (res.status == true) {
          this.isLoading = false;
          if (res.data.data.length < 1) {
            alert(
              "Sorry, we couldn't load the file because an error occured. "
            );
          }
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async handleLoadVersionHistory(version) {
      this.isLoading = true;
      let payload = { source: this.source, version: version.bulkTimestamp };
      this.versionHistoryActive = true;
      this.versionHistory = version.bulkTimestamp;
      try {
        let res = await this.$store.dispatch("LOAD_VERSION_HISTORY", payload);
        if (res.status == true) {
          this.isLoading = false;
          this.toggleVersionHistory = false;
          this.currentPage = 1;
          if (res.data.data.length < 1) {
            alert(
              "Sorry, we couldn't load the file because an error occured. "
            );
          }
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async fetchVersionHistory() {
      this.isLoading = true;
      // let payload = {source: this.source}
      try {
        let res = await this.$store.dispatch("FETCH_VERSION_HISTORY", {
          source: this.source,
        });
        if (res.status == true) {
          this.isLoading = false;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async handleVersionFilterBtn() {
      let payload = {
        tag: this.versionHistorySearch,
        searchByDateFrom: this.searchByDateFrom,
        searchByDateTo: this.searchByDateTo,
        source: this.source,
      };
      this.isLoadingInline = true;
      try {
        let res = await this.$store.dispatch("SEARCH_VERSION_HISTORY", payload);
        if (res.status == true) {
          this.isLoadingInline = false;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoadingInline = false;
      }
    },
    clearVersionFilterBtn() {
      this.currentPage = 1;
      this.versionHistorySearch = "";
      if (this.searchByDateTo == "" && this.searchByDateFrom == "")
        this.fetchVersionHistory();
    },
    async handleSearchBtn() {
      this.isLoading = true;
      this.searchActive = true;
      let payload = {
        search: this.search,
        source: this.source,
        versionHistoryActive: this.versionHistoryActive,
        versionHistory: this.versionHistory,
      };
      this.currentPage = 1;
      try {
        let res = await this.$store.dispatch("SEARCH_SHEET", payload);

        if (res.status) {
          this.isLoading = false;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    clearSearchBtn() {
      this.search = "";
      this.currentPage = 1;
      this.searchActive = false;
      this.fetchRecords();
    },
    async resetRecords() {
      this.versionHistoryActive = false;
      this.currentPage = 1;
      this.search = "";
      this.searchActive = false;
      this.fetchRecords();
    },
    async handleLocationUpload() {
      this.isLoading = true;
      this.searchActive = false;
      this.versionHistoryActive = false;
      let payload = {
        versionName: this.tags,
        fileUrl: this.uploadURL,
      };
      try {
        let res = await this.$store.dispatch("UPLOAD_SHEET", {
          payload,
          source: this.source,
        });
        if (res.status == true) {
          this.isLoading = false;
          this.promptModal = false;
          alert(res.message);
          this.fetchRecords();
          this.fetchVersionHistory();
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
          this.isLoading = false;
        }
      } catch (error) {}
    },
    async onHandleFile(file) {
      this.isLoadingInline = true;
      this.file = file;
      try {
        let res = await this.$store.dispatch("HANDLE_FILE_UPLOAD", file);
        if (res.status == true) {
          this.uploadURL = res.data;
          this.isLoadingInline = false;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}
    },

    handleClearDateFilterBtn() {
      this.searchByDateTo = "";
      this.searchByDateFrom = "";
      this.currentPage = 1;
      if (this.versionHistorySearch == "") this.fetchVersionHistory();
    },

    async prevPage() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage - 1,
        searchActive: this.searchActive,
        search: this.search,
        source: this.source,
        versionHistory: this.versionHistory,
        versionHistoryActive: this.versionHistoryActive,
      };
      try {
        let res = await this.$store.dispatch("PAGINATE_SHEET", payload);
        if (res.status) {
          this.isLoading = false;
          this.currentPage -= 1;
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
    async nextPage() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage + 1,
        searchActive: this.searchActive,
        search: this.search,
        source: this.source,
        versionHistory: this.versionHistory,
        versionHistoryActive: this.versionHistoryActive,
      };
      try {
        let res = await this.$store.dispatch("PAGINATE_SHEET", payload);
        if (res.status) {
          this.isLoading = false;
          this.currentPage += 1;
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.tel::-webkit-inner-spin-button,
.tel::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.tel {
  -moz-appearance: textfield;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.inline-loading {
  /* position: fixed; */
  z-index: 999;
  /* height: 0.3em; */
  /* width: 0.3em; */
  display: block;
  float: right;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* :not(:required) hides these rules from IE9 and below */
.inline-loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.inline-loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 0.5em;
  height: 0.5em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.icon-cancel {
  position: absolute;
  top: 8%;
  right: 1%;
}

.icon-sm-cancel {
  position: absolute;
  top: 15%;
  right: 76.5%;
}

.icon-sm-clear-cancel {
  position: absolute;
  top: 15%;
  right: 42%;
}

.loader-line {
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  /* margin: 100px auto; */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: block;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #003282;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
</style>
